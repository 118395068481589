import { useParallax } from "@/javascripts/components/parallax";
import AOS from "aos";

// -----------------------------------------------------------
// パララックス
// -----------------------------------------------------------
window.addEventListener("DOMContentLoaded", () => {
  // data-parallaxのオブジェクトのbackground-size: containにする
  document.querySelectorAll("[data-parallax]").forEach((element) => {
    // .style-initializeを削除する
    element.classList.remove("style-initialize");
  });
  useParallax();
});

// -----------------------------------------------------------
// フェードイン、ズームイン
// -----------------------------------------------------------
AOS.init({ once: true });
//
// 使用方法の補足
//
// 種類:
//   data-aos="fade-in" ["fade-in" | "fade-up" ]
// 開始位置（ピクセル単位）:
//   data-aos-offset="120"
// 開始位置（相対）
//   data-aos-anchor-placement="top-center" // 第1要素: 対象エレメントのどこに来たら発動するか。第2要素: それが画面のどこを通過したら発動するか。
// アニメーション時間:
//   data-aos-duration="400"
// イージング:
//   data-aos-easing="ease-in-out"
// 遅延:
//   data-aos-delay="50"
// 一回だけ実行:
//   data-aos-once="true"
// 例:
//   .home-topics__header[data-aos="fade-in" data-aos-offset="100" data-aos-duration="300"]
